export default [
  {
    path: '/smtpconfig',
    name: 'Smtpconfig',
    component: () => import('@/views/Core/Smtpconfig/Index.vue'),
    meta: {
      pageTitle: 'SMTP Yapılandırma',
      breadcrumb: [
        {
          text: 'SMTP Yapılandırma',
          active: true,
        },
      ],
      resource: 'SMTP_CONFIG',
      action: 'read',
    },
  },
  {
    path: '/user_types',
    name: 'UserTypes',
    component: () => import('@/views/Core/User_types/Index.vue'),
    meta: {
      pageTitle: 'Kullanıcı Seviyeleri',
      breadcrumb: [
        {
          text: 'Kullanıcı Seviyeleri',
          active: true,
        },
      ],
      resource: 'USER_TYPES_LIST',
      action: 'read',
    },
  },
  {
    path: '/user_types/add',
    name: 'UserTypesAdd',
    component: () => import('@/views/Core/User_types/Add.vue'),
    meta: {
      pageTitle: 'Kullanıcı Seviyeleri',
      breadcrumb: [
        {
          text: 'Kullanıcı Seviyeleri',
          to: '/user_types',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'USER_TYPES_ADD',
      action: 'read',
    },
  },
  {
    path: '/user_types/edit/:id',
    name: 'UserTypesEdit',
    component: () => import('@/views/Core/User_types/Edit.vue'),
    meta: {
      pageTitle: 'Kullanıcı Seviyeleri',
      breadcrumb: [
        {
          text: 'Kullanıcı Seviyeleri',
          to: '/user_types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'USER_TYPES_EDIT',
      action: 'read',
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Core/Users/Index.vue'),
    meta: {
      pageTitle: 'Kullanıcılar',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
          active: true,
        },
      ],
      resource: 'USERS_LIST',
      action: 'read',
    },
  },
  {
    path: '/users/add',
    name: 'UsersAdd',
    component: () => import('@/views/Core/Users/Add.vue'),
    meta: {
      pageTitle: 'Kullanıcılar',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
          to: '/users',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'USERS_ADD',
      action: 'read',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'UsersEdit',
    component: () => import('@/views/Core/Users/Edit.vue'),
    meta: {
      pageTitle: 'Kullanıcılar',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
          to: '/users',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'USERS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Core/Users/Profile.vue'),
    meta: {
      pageTitle: 'Profilim',
      breadcrumb: [
        {
          text: 'Profilim',
          to: '/profile',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PROFILE',
      action: 'read',
    },
  },
]
