import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import smtpConfig from '@/store/core/smtpconfig'
import userTypes from '@/store/core/user_types'
import users from '@/store/core/users'
import abilities from '@/store/core/abilities'
import logs from '@/store/logs'
import dashboard from '@/store/core/dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    smtpConfig,
    userTypes,
    users,
    abilities,
    logs,
    dashboard,
  },
  strict: process.env.DEV,
})
