import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dashboard: {},
  },
  getters: {
    getDashboard(state) {
      return state.dashboard
    },
  },
  mutations: {
    DASHBOARD(state, data) {
      state.dashboard = data
    },
  },
  actions: {
    dashboard({ commit }) {
      axiosIns
        .post('/dashboard')
        .then(response => {
          commit('DASHBOARD', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
