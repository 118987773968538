import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    listLogs: [],
    listCount: 0,
    viewLogs: [],
    viewCount: 0,
    manuelSynced: {},
  },
  getters: {
    getListLogs(state) {
      return state.listLogs
    },
    getListCount(state) {
      return state.listCount
    },
    getViewLogs(state) {
      return state.viewLogs
    },
    getViewCount(state) {
      return state.viewCount
    },
    getManuelSynced(state) {
      return state.manuelSynced
    },
  },
  mutations: {
    LIST_LOGS(state, data) {
      state.listLogs = data
    },
    VIEW_LOGS(state, data) {
      state.viewLogs = data
    },
    LIST_LOGS_COUNT(state, data) {
      state.listCount = data
    },
    VIEW_LOGS_COUNT(state, data) {
      state.viewCount = data
    },
    MANUEL_SYNCED(state, data) {
      state.manuelSynced = data
    },
  },
  actions: {
    logsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/logs', data)
        .then(response => {
          commit('LIST_LOGS', response.data.data)
          commit('LIST_LOGS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    logsView({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/logs/view', data)
        .then(response => {
          commit('VIEW_LOGS', response.data.data)
          commit('VIEW_LOGS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    manuelSync({ commit }) {
      axiosIns
        .post('/logs/manuelSync')
        .then(response => {
          commit('MANUEL_SYNCED', response.data.data)
        })
        .catch(error => console.error(error))
    },
    resetSynced({ commit }) {
      commit('MANUEL_SYNCED', {})
    },
  },
}
